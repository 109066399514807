<template lang="pug">
	.widget
		el-card.box-card
			el-row(slot="header")
				el-col.text-left(:span="24")
					.d-flex.justify-content-between
						h3 Help And Instructions
			#section1.text-left
				h5 Description
				p This application is of type of a CRM specifically designed for affiliates and advertisers. The platform is modular, it consists of a CRM, API, Job Processor
					| and various microservices.
				p The CRM is devided into 4 parts:
				ul
					li <b>Dashboard</b> - Main dashboard with charts
					li <b>Leads</b> - Leads and customers list with filters
					li <b>Integrations</b> - Settings for external applications
					li <b>Jobs</b> - Cron Jobs - used to fetch statuses from the broker / external API

				p
					i.text-info The help manual is under construction
				h5 Links
				a(href="https://docs.google.com/spreadsheets/d/1zUJZIQRUSIbA2skpkSoZe2H5F8m7o-hIAnISF6EdXOU/edit#gid=0") Template - Spreadsheet for "Google-Sheets" part

</template>

<style lang="scss">

</style>

<script>
export default {

}
</script>
